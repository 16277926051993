import { motion } from "framer-motion";
import { Building, Clock, Mail, Phone, User } from "lucide-react";
import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface SchedulingStepProps {
  onBack: () => void;
  formData: {
    companySize?: string;
    services?: string[];
  };
  onSubmit: (contactInfo: ContactInfo, appointmentTime: Date) => void;
}

interface ContactInfo {
  name: string;
  email: string;
  phone: string;
  companyName: string;
  message?: string;
}

const SchedulingStep: React.FC<SchedulingStepProps> = ({
  onBack,
  formData,
  onSubmit,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [contactInfo, setContactInfo] = useState<ContactInfo>({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    message: "",
  });

  const handleDateChange = (value: Value) => {
    if (value instanceof Date) {
      setSelectedDate(value);
    }
  };
  // Generate available time slots (9 AM - 9 PM Dubai time)
  const generateTimeSlots = () => {
    const slots = [];
    for (let hour = 9; hour < 21; hour++) {
      slots.push(`${hour.toString().padStart(2, "0")}:00`);
    }
    return slots;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedDate && selectedTime) {
      const [hours, minutes] = selectedTime.split(":");
      const appointmentTime = new Date(selectedDate);
      appointmentTime.setHours(parseInt(hours), parseInt(minutes), 0);
      onSubmit(contactInfo, appointmentTime);
    }
  };

  // Custom styles for react-calendar to match your theme
  const calendarClassName = `
    rounded-xl shadow-lg border border-gray-100 overflow-hidden
    [&_.react-calendar__navigation]:bg-[#5D4A82]
    [&_.react-calendar__navigation__label]:text-white
    [&_.react-calendar__navigation__arrow]:text-white
    [&_.react-calendar__month-view__weekdays]:bg-gray-50
    [&_.react-calendar__month-view__weekdays__weekday]:text-[#5D4A82]
    [&_.react-calendar__tile--active]:bg-[#5D4A82]
    [&_.react-calendar__tile--active:enabled:hover]:bg-[#856BAE]
    [&_.react-calendar__tile--active:enabled:focus]:bg-[#856BAE]
    [&_.react-calendar__tile--now]:bg-[#856BAE]/20
    [&_.react-calendar__tile:enabled:hover]:bg-[#856BAE]/20
    [&_.react-calendar__tile:enabled:focus]:bg-[#856BAE]/20
  `;

  // Function to check if a date should be disabled
  const tileDisabled = ({ date }: { date: Date }) => {
    // Disable past dates and weekends
    return (
      date < new Date(new Date().setHours(0, 0, 0, 0)) ||
      date.getDay() === 0 ||
      date.getDay() === 6
    );
  };

  return (
    <div className="py-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center mb-12"
      >
        <h2 className="text-3xl md:text-4xl font-bold text-[#5D4A82] mb-4">
          Schedule Your Consultation
        </h2>
        <p className="text-gray-600 max-w-2xl mx-auto">
          Let's discuss how we can help your{" "}
          {formData.companySize?.toLowerCase()} business
          {formData.services && formData.services.length > 0 && (
            <span> with {formData.services.join(", ")}</span>
          )}
        </p>
      </motion.div>

      <form onSubmit={handleSubmit} className="max-w-3xl mx-auto">
        <div className="grid md:grid-cols-2 gap-8">
          {/* Contact Information */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
            className="space-y-6"
          >
            <h3 className="text-xl font-semibold text-[#5D4A82] mb-4">
              Contact Information
            </h3>

            <div className="space-y-4">
              <div className="relative">
                <User className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  required
                  placeholder="Your Name"
                  value={contactInfo.name}
                  onChange={(e) =>
                    setContactInfo({ ...contactInfo, name: e.target.value })
                  }
                  className="w-full pl-12 pr-4 py-3 rounded-lg border border-gray-200
                    focus:ring-2 focus:ring-[#5D4A82] focus:border-transparent
                    transition-all duration-300"
                />
              </div>

              <div className="relative">
                <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="email"
                  required
                  placeholder="Email Address"
                  value={contactInfo.email}
                  onChange={(e) =>
                    setContactInfo({ ...contactInfo, email: e.target.value })
                  }
                  className="w-full pl-12 pr-4 py-3 rounded-lg border border-gray-200
                    focus:ring-2 focus:ring-[#5D4A82] focus:border-transparent
                    transition-all duration-300"
                />
              </div>

              <div className="relative">
                <Phone className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="tel"
                  required
                  placeholder="Phone Number"
                  value={contactInfo.phone}
                  onChange={(e) =>
                    setContactInfo({ ...contactInfo, phone: e.target.value })
                  }
                  className="w-full pl-12 pr-4 py-3 rounded-lg border border-gray-200
                    focus:ring-2 focus:ring-[#5D4A82] focus:border-transparent
                    transition-all duration-300"
                />
              </div>

              <div className="relative">
                <Building className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  required
                  placeholder="Company Name"
                  value={contactInfo.companyName}
                  onChange={(e) =>
                    setContactInfo({
                      ...contactInfo,
                      companyName: e.target.value,
                    })
                  }
                  className="w-full pl-12 pr-4 py-3 rounded-lg border border-gray-200
                    focus:ring-2 focus:ring-[#5D4A82] focus:border-transparent
                    transition-all duration-300"
                />
              </div>

              <textarea
                placeholder="Additional Message (Optional)"
                value={contactInfo.message}
                onChange={(e) =>
                  setContactInfo({ ...contactInfo, message: e.target.value })
                }
                className="w-full p-4 rounded-lg border border-gray-200 h-32
                  focus:ring-2 focus:ring-[#5D4A82] focus:border-transparent
                  transition-all duration-300 resize-none"
              />
            </div>
          </motion.div>

          {/* Calendar Section */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3 }}
            className="space-y-6"
          >
            <h3 className="text-xl font-semibold text-[#5D4A82] mb-4">
              Select Date & Time
            </h3>

            <div className="bg-white rounded-xl shadow-lg p-4 border border-gray-100">
              {/* Calendar Component */}
              <div className="mb-6">
                <Calendar
                  onChange={handleDateChange}
                  value={selectedDate}
                  className={calendarClassName}
                  minDate={new Date()}
                  tileDisabled={tileDisabled}
                  view="month"
                />
              </div>

              {/* Time Slots */}
              {selectedDate && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="space-y-4"
                >
                  <h4 className="font-medium text-[#5D4A82] flex items-center gap-2">
                    <Clock className="w-5 h-5" />
                    Available Time Slots (Dubai Time)
                  </h4>
                  <div className="grid grid-cols-3 gap-2">
                    {generateTimeSlots().map((time) => (
                      <button
                        key={time}
                        type="button"
                        onClick={() => setSelectedTime(time)}
                        className={`p-2 rounded-lg text-sm font-medium
                          transition-all duration-300 
                          ${
                            selectedTime === time
                              ? "bg-gradient-to-r from-[#5D4A82] to-[#856BAE] text-white"
                              : "bg-gray-50 text-gray-600 hover:bg-gray-100"
                          }`}
                      >
                        {time}
                      </button>
                    ))}
                  </div>
                </motion.div>
              )}
            </div>
          </motion.div>
        </div>

        {/* Navigation Buttons */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="flex justify-center space-x-4 mt-12"
        >
          <button
            type="button"
            onClick={onBack}
            className="px-8 py-3 rounded-lg font-semibold text-lg
              border-2 border-[#5D4A82] text-[#5D4A82]
              transition-all duration-300 hover:bg-[#5D4A82] hover:text-white"
          >
            Back
          </button>
          <button
            type="submit"
            disabled={!selectedDate || !selectedTime || !contactInfo.email}
            className={`px-8 py-3 rounded-lg font-semibold text-lg
              transition-all duration-300 transform hover:scale-105
              ${
                selectedDate && selectedTime && contactInfo.email
                  ? "bg-gradient-to-r from-[#5D4A82] to-[#856BAE] text-white shadow-lg hover:shadow-xl"
                  : "bg-gray-100 text-gray-400 cursor-not-allowed"
              }`}
          >
            Schedule Meeting
          </button>
        </motion.div>
      </form>
    </div>
  );
};

export default SchedulingStep;
