import { motion } from "framer-motion";
import {
  BarChart3,
  Building,
  CalendarDays,
  Clock,
  ListTodo,
  Users,
} from "lucide-react";
import { useState } from "react";
import AppointmentCalendar from "./AppointmentCalendar";
import AppointmentList from "./AppointmentList";
import StatsOverview from "./StatsOverview";

type ViewType = "list" | "calendar" | "stats";

const AdminDashboard = () => {
  const [currentView, setCurrentView] = useState<ViewType>("list");

  const navigationItems = [
    { id: "list", label: "Appointments", icon: ListTodo },
    { id: "calendar", label: "Calendar", icon: CalendarDays },
    { id: "stats", label: "Overview", icon: BarChart3 },
  ];

  const renderView = () => {
    switch (currentView) {
      case "calendar":
        return <AppointmentCalendar />;
      case "stats":
        return <StatsOverview />;
      default:
        return <AppointmentList />;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-8">
        {/* Dashboard Header */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-xl shadow-lg p-6 mb-8"
        >
          <h1 className="text-3xl font-bold text-[#5D4A82] mb-2">
            Admin Dashboard
          </h1>
          <p className="text-gray-600">
            Manage appointments and view business insights
          </p>
        </motion.div>

        {/* Quick Stats */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          {[
            {
              label: "Total Appointments",
              value: "24",
              icon: Users,
              color: "from-purple-500 to-indigo-500",
            },
            {
              label: "Upcoming Today",
              value: "5",
              icon: Clock,
              color: "from-blue-500 to-cyan-500",
            },
            {
              label: "This Week",
              value: "18",
              icon: CalendarDays,
              color: "from-emerald-500 to-teal-500",
            },
            {
              label: "Companies",
              value: "12",
              icon: Building,
              color: "from-orange-500 to-red-500",
            },
          ].map((stat) => (
            <motion.div
              key={stat.label}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white rounded-xl shadow-lg p-6"
            >
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-gray-600 text-sm">{stat.label}</p>
                  <h3 className="text-2xl font-bold text-[#5D4A82] mt-1">
                    {stat.value}
                  </h3>
                </div>
                <div
                  className={`p-3 rounded-lg bg-gradient-to-r ${stat.color} text-white`}
                >
                  <stat.icon className="w-6 h-6" />
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Navigation */}
        <div className="bg-white rounded-xl shadow-lg p-4 mb-8">
          <div className="flex space-x-4">
            {navigationItems.map((item) => (
              <button
                key={item.id}
                onClick={() => setCurrentView(item.id as ViewType)}
                className={`flex items-center space-x-2 px-4 py-2 rounded-lg
                  transition-all duration-300
                  ${
                    currentView === item.id
                      ? "bg-gradient-to-r from-[#5D4A82] to-[#856BAE] text-white"
                      : "text-gray-600 hover:bg-gray-50"
                  }`}
              >
                <item.icon className="w-5 h-5" />
                <span>{item.label}</span>
              </button>
            ))}
          </div>
        </div>

        {/* Main Content */}
        <motion.div
          key={currentView}
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -20 }}
          transition={{ duration: 0.3 }}
          className="bg-white rounded-xl shadow-lg"
        >
          {renderView()}
        </motion.div>
      </div>
    </div>
  );
};

export default AdminDashboard;
